import React from "react";
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { StockChartProps } from "../types/chatinterface";
import moment from 'moment';
import { formats } from "../component/formats";

const now = new Date();
const minTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 7, 0, 0).getTime();
const maxTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0).getTime();
const formattedMinTime: any = moment(minTime).format(formats.minute);
const formattedMaxTime: any = moment(maxTime).format(formats.minute);

const CandleChart: React.FC<StockChartProps> = ({ dates, categories, name, onTimeRangeChange, timeRange, refreshChatData, afterChangeData }) => {


  const chartDataOptions = {
    series: [{ name, data: dates }],
    options: {
      chart: {
        type: 'candlestick',
        height: 463,
        stacked: false,
        zoom: {
          enabled: true,
        },

      },

      xaxis: {
        category: categories,
        tickAmount: 12,
        min: formattedMinTime,
        max: formattedMaxTime,



      },
      yaxis: {
        title: {
          text: '',
        },

      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy',
        },
        style: {
          background: '#000000', // Set tooltip background color to black
          color: '#FFFFFF', // Optional: Set tooltip text color to white for contrast
        }
      },

      dataLabels: {
        enabled: false,
      },
      colors: ['#3E1A79', '#7345FF'],
      stroke: {
        width: 1,
        curve: 'smooth',
      },
      plotOptions: {
        candlestick: {
          colors: {
            upward: '#7345FF',
            downward: '#3E1A79',
          },
        },
      },
    },
  };


  return (
    <div>
      <div>
        <ReactApexChart
          options={chartDataOptions.options as ApexOptions}
          series={chartDataOptions.series}
          type="candlestick"
          height={463}
          width="100%"
        />
      </div>
    </div>
  );
};

export default CandleChart;
