import React, { useMemo } from 'react';
import Marquee from "react-fast-marquee";
import MarketDataComponent from './MarketDataComponent';

interface MarqueeHeaderProps {
    data: any[]
}
const MarqueeHeader: React.FC<MarqueeHeaderProps> = ({ data }) => {
    const memoizedMarketItems = useMemo(() => {
        return data.map((item, index) => (
            <div className="all-data" key={item.symbol}>
                <MarketDataComponent {...item} />
            </div>
        ));
    }, [data]);
    return <Marquee loop={0}>
        {memoizedMarketItems}
    </Marquee>
};

export default MarqueeHeader;