import React, { useEffect, useState } from 'react';
import { fetchData } from '../apis/Apis';
import { endpoints, SERVER_URL } from '../constants/environments';

interface TradeData {
  tickerName: string;
  latestPrice: number;
  percentageChange: string;
}

const RecentlyViewTicker: React.FC = () => {
  const [recentlyViewed, setRecentlyViewed] = useState<string[]>([]);
  const [responseData, setResponseData] = useState<TradeData[]>([]);

  const getRecentlyViewedTickers = () => {
    const storedData = localStorage.getItem('lastTwoTradeData');
    if (storedData) {
      const parsedData: string[] = JSON.parse(storedData);
      if (parsedData.length === 1) {
        setRecentlyViewed([parsedData[0]]); 
      } else {
        setRecentlyViewed(parsedData.slice(-5).reverse());  // Retrieve and reverse the last 5 items
      }
    }
};


  const getTradeData = async (ticker: string) => {
    try {
      const response = await fetchData(`${endpoints.GET_LAST_TRADE_DATA}/${ticker}`);
      const trade: TradeData = response.result;
      setResponseData((prevData) => [...prevData, trade]); 
    } catch (error) {
      console.error('Error fetching trade data', error);
    }
  };

  useEffect(() => {
    getRecentlyViewedTickers();
  }, []);

  useEffect(() => {
    if (recentlyViewed.length > 0) {
      recentlyViewed.forEach((ticker) => {
        getTradeData(ticker); 
      });
    }
  }, [recentlyViewed]);

  const handleClick = (tickerName: string) => {
    window.location.href =  `${SERVER_URL}${tickerName}`;
  };
  

  return (
    <div>
          <div className="widget p-32">
            <h1 className="f-20 semi">Recently Viewed Tickers</h1>
            {responseData.length > 0 ? (
              responseData.map((ticker, index) => (
                <div key={index} className="dark-box _w-316 mt-24">
                  <div>
                  <h4   style={{ cursor: 'pointer' }}
 onClick={() => handleClick(ticker?.tickerName)} className="stock_name f-16">
  {ticker?.tickerName}
</h4>
                    <div className="f-16 white">{ticker?.latestPrice} USD</div>
                  </div>
                  <div className="data">
                    <img
                      src={
                        parseFloat(ticker?.percentageChange) >= 0
                          ? "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da92317caf51c5508aa041_Polygon%202%20(1).svg"
                          : "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da97be2f98a346093a82b1_Polygon%201.svg"
                      }
                      loading="lazy"
                      alt={parseFloat(ticker?.percentageChange) >= 0 ? "up" : "down"}
                      className={parseFloat(ticker?.percentageChange) >= 0 ? "upside" : "downside"}
                    />
                    <div className={`text-block-2 ${parseFloat(ticker?.percentageChange) >= 0 ? 'green' : 'red'}`}>
                      {ticker?.percentageChange}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>No recently viewed tickers.</div>
            )}
          </div>

         

        
        </div>
    
  );
};

export default RecentlyViewTicker;
