import { useState, useEffect, useCallback } from 'react';
import { fetchData } from '../apis/Apis';
import { endpoints } from '../constants/environments';
import websocketService, { SocketType } from '../sockets/polygon';
import { TabEnum } from '../constants/tabs';
import { symbols } from '../constants/symbols';
import { calculateChange } from '../services/calcculateChange';

export interface MarketIndexData {
    ticker: string;
    session: {
        previous_close: number;
        price: number;
        change?: number;
        change_percent?: number;
    };
    fmv?: number; // Last market value
    last_quote?: {
        ask: number;
    };
}

const useMarketIndex = () => {
    const [marketIndexData, setMarketIndexData] = useState<{
        [key in TabEnum]: MarketIndexData[];
    }>({
        [TabEnum.Stocks]: [],
        [TabEnum.Forex]: [],
        [TabEnum.Crypto]: [],
    });
    const [activeTab, setActiveTab] = useState<TabEnum>(TabEnum.Stocks); // Default active tab

    const fetchMarketIndexData = useCallback(async (type: TabEnum) => {
        try {
            const response = await fetchData(`${endpoints.MARKET_INDEX}?type=${type.toLowerCase()}`);
            const results = response.result.map((res: any) => ({
                ...res,
                ticker: type === TabEnum.Stocks ? res.ticker : res.ticker.replace(/USD|JPY/, "-$&"),
            })) as MarketIndexData[];

            setMarketIndexData((prevData) => ({
                ...prevData,
                [type]: results,
            }));
        } catch (error) {
            console.error('Error fetching market index data:', error);
        }
    }, []);

    const handleWebSocketSubscriptions = useCallback((type: TabEnum) => {
        const socketType: SocketType = type === TabEnum.Crypto
            ? SocketType.CRYPTO
            : type === TabEnum.Forex
                ? SocketType.FOREX
                : SocketType.STOCKS;

        const symbolsList = symbols[type];

        const unsubscribeFunctions: (() => void)[] = [];

        symbolsList.split(",").forEach((symbol) => {
            const unsubscribeFn = websocketService.subscribe(symbol, socketType);
            // unsubscribeFunctions.push(unsubscribeFn);

            websocketService.onMessage(socketType, symbol, (data: any) => {
                setMarketIndexData((prevData) => {
                    const updatedData = prevData[type].map((item) => {
                        if (item.ticker === data.sym) {
                            const previousClose = item.session.previous_close;
                            const { change, changePercent } = calculateChange(data.fmv, previousClose);
                            return {
                                ...item,
                                fmv: data.fmv,
                                session: {
                                    ...item.session,
                                    price: data.fmv,
                                    change: parseFloat(change.toFixed(2)),
                                    change_percent: parseFloat(changePercent.toFixed(2)),
                                },
                            };
                        }
                        return item;
                    });
                    return { ...prevData, [type]: updatedData };
                });
            });
        });

        // Return a cleanup function to unsubscribe from all subscriptions
        return () => {
            unsubscribeFunctions.forEach(unsub => unsub());
        };
    }, []);

    useEffect(() => {
        // Load default data for all tabs on initial render
        fetchMarketIndexData(activeTab);

        // Set up WebSocket subscriptions for the active tab
        const unsubscribeWebSocket = handleWebSocketSubscriptions(activeTab);

        return () => {
            unsubscribeWebSocket(); // Clean up WebSocket subscriptions on tab change
        };
    }, [fetchMarketIndexData, handleWebSocketSubscriptions, activeTab]);

    useEffect(() => {
        // Load default data for all tabs on initial render
        Object.values(TabEnum).forEach((tab) => {
            fetchMarketIndexData(tab);
        });

        // Set up WebSocket subscriptions for the active tab
        const unsubscribeWebSocket = handleWebSocketSubscriptions(activeTab);

        return () => {
            unsubscribeWebSocket(); // Clean up WebSocket subscriptions on tab change
        };
    }, []);

    const updateActiveTab = (newTab: TabEnum) => {
        setActiveTab(newTab);
    };

    return { marketIndexData, activeTab, updateActiveTab };
};

export default useMarketIndex;
