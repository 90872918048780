import React, { useCallback, useEffect, useRef, useState } from 'react';
import { fetchData } from '../apis/Apis';
import { endpoints } from '../constants/environments';

interface TradeItem {
  ticker: string;
  name: string;
  priceChange: number;
  percentageChange: string;
  URL: string;
}

const QuotesSearch = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [responseData, setResponseData] = useState<TradeItem[]>([]);
  const [error, setError] = useState<string | null>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null); 

  const getSearchQueryData = useCallback(async (ticker: string) => {
    if (!ticker) return;
    try {
      const response: any = await fetchData(`${endpoints.Search_Data}/${ticker}`);
      setResponseData(response);
      setError(null);
    } catch (error) {
      console.error('Error fetching trade data', error);
      setError('Error fetching data. Please try again.');
    }
  }, []);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchQuery) {
        getSearchQueryData(searchQuery);
      } else {
        setResponseData([]);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery, getSearchQueryData]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setResponseData([]);
  };

  // Handle clicks outside of the search results
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchResultsRef.current && !searchResultsRef.current.contains(event.target as Node)) {
        setResponseData([]); // Clear the search results
      }
    };
  
    if (searchResultsRef.current) {
      document.addEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchResultsRef]);
  

  return (
    <div>
      <div className="search-bar">
        <img 
          src="https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da9d99087f1590a3891013_mynaui_search.svg" 
          loading="lazy" 
          alt="" 
          className="image-2" 
        />
        <input 
          className="text-field _w-200 h-36 w-input" 
          name="name" 
          data-name="Name" 
          placeholder="Search for quotes" 
          type="text" 
          id="name" 
          onChange={handleSearchChange}
        />
        {responseData.length > 0 && (
          <div className="search-result-data" ref={searchResultsRef}> {/* Attach ref here */}
            <table>
              <tbody>
                {responseData.map((item) => (
                  <tr key={item.ticker} className="border">
                    <td>
                      <div className={`line ${Number(item.percentageChange) > 0 ? 'green' : 'red'}`}></div>
                    </td>
                    <td>
                      <a href={item.URL} aria-label={`View details for ${item.name}`}>
                        <span className="symbol">{item.ticker}</span>
                        <h6 className="company-name">{item.name}</h6>
                      </a>
                    </td>
                    <td className="text-end">
                      <span className="rate">{item.priceChange}</span>
                      <div className="flex">
                        <img
                          src={Number(item.percentageChange) > 0 
                            ? 'https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da92317caf51c5508aa041_Polygon%202%20(1).svg' 
                            : 'https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da97be2f98a346093a82b1_Polygon%201.svg'}
                          loading="lazy"
                          alt="percentage change icon"
                          className="up-icon"
                        />
                        <h6 className={`percentage ${Number(item.percentageChange) > 0 ? 'green' : 'red'}`}>
                          {item.percentageChange}%
                        </h6>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {searchQuery && responseData.length === 0 && <p>No results found</p>}
      </div>
    </div>
  );
};

export default QuotesSearch;
