import { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const useMarketHours = (timezone = 'America/New_York') => {
    const [marketHours, setMarketHours] = useState<{ effectiveMarketOpenTime: null | any, effectiveMarketCloseTime: null | any, currentLocalTime: null | number, isCurrentTimeInBetweenNineThirtyAndFour: boolean }>({
        effectiveMarketOpenTime: null,
        effectiveMarketCloseTime: null,
        currentLocalTime: null,
        isCurrentTimeInBetweenNineThirtyAndFour: false
    });

    useEffect(() => {
        const currentUTCTime = moment.utc();
        const currentLocalTime = currentUTCTime.tz(timezone);

        const todayDate = currentLocalTime.format('YYYY-MM-DD');
        const marketOpenTime = moment.tz(`${todayDate} 09:30:00`, timezone).valueOf();
        const marketCloseTime = moment.tz(`${todayDate} 16:00:00`, timezone).valueOf();

        const currentDayOfWeek = currentLocalTime.day();
        let effectiveMarketOpenTime, effectiveMarketCloseTime;

        if (currentDayOfWeek === 0 || currentDayOfWeek === 6) { // Sunday or Saturday
            const previousFriday = currentLocalTime.subtract(currentDayOfWeek === 0 ? 2 : 1, 'days').format('YYYY-MM-DD');
            effectiveMarketOpenTime = moment.tz(`${previousFriday} 09:30:00`, timezone).valueOf();
            effectiveMarketCloseTime = moment.tz(`${previousFriday} 16:00:00`, timezone).valueOf();
        } else if (currentDayOfWeek >= 1 && currentDayOfWeek <= 5) { // Monday - Friday
            if (currentLocalTime.isBefore(marketOpenTime) || currentLocalTime.isAfter(marketCloseTime)) {
                // Use previous day's market hours
                const previousDay = currentLocalTime.subtract(currentDayOfWeek === 1 ? 3 : 1, 'days').format('YYYY-MM-DD');
                effectiveMarketOpenTime = moment.tz(`${previousDay} 09:30:00`, timezone).valueOf();
                effectiveMarketCloseTime = moment.tz(`${previousDay} 16:00:00`, timezone).valueOf();
            } else {
                effectiveMarketOpenTime = marketOpenTime;
                effectiveMarketCloseTime = marketCloseTime;
            }
        }

        setMarketHours({
            effectiveMarketOpenTime,
            effectiveMarketCloseTime,
            currentLocalTime: currentLocalTime.valueOf(),
            isCurrentTimeInBetweenNineThirtyAndFour: currentLocalTime.isBetween(effectiveMarketOpenTime, effectiveMarketCloseTime)
        });
    }, [timezone]);

    return marketHours;
};

export default useMarketHours;
