import { useState, useEffect, useCallback } from 'react';
import { fetchData } from '../apis/Apis';
import { endpoints } from '../constants/environments';
import { calculateChange } from '../services/calcculateChange';
import websocketService, { SocketType } from '../sockets/polygon';


const useMarketData = () => {
    const [marketInfo, setMarketInfo] = useState<any[]>([]);

    // Fetch market data
    const getMarketData = useCallback(async () => {
        try {
            const response = await fetchData(`${endpoints.MARKET_DATA}`);
            setMarketInfo(response.result.responseData);
        } catch (error) {
            console.error('Error fetching market data:', error);
        }
    }, []);

    // Initial fetch of market data
    useEffect(() => {
        getMarketData();
    }, [getMarketData]);

    // WebSocket updates for market data
    useEffect(() => {
        const stockSymbols = 'XLC,XLY,XLP,XLE,XLF,XLV,XLI,XLB,XLRE,XLK,XLU';

        stockSymbols.split(",").forEach((sym) => {
            websocketService.subscribe(sym, SocketType.STOCKS);

            websocketService.onMessage(SocketType.STOCKS, sym, (data: any) => {
                const latestPrice: number = data.fmv;
            
                setMarketInfo((prevData) =>
                    prevData.map((item) => {
                        const itemSymbol = item.symbol.toString();
                        const dataSymbol = data.sym.toString();
            
                        if (itemSymbol === dataSymbol) {
                            const previousClose = item.closePrice;
                            
                            // Check if both latestPrice and previousClose are valid numbers
                            if (typeof latestPrice === 'number' && !isNaN(latestPrice) &&
                                typeof previousClose === 'number' && !isNaN(previousClose)) {
                                
                                const { changePercent } = calculateChange(latestPrice, previousClose);
            
                                return {
                                    ...item,
                                    fmv: latestPrice,
                                    change: `${changePercent.toFixed(2)}%`, // Added % symbol
                                    direction: changePercent >= 0 ? 'upside' : 'downside',
                                };
                            } else {
                                // If values are invalid, return item without updating change and direction
                                return { ...item, fmv: latestPrice, change: 'N/A', direction: 'N/A' };
                            }
                        }
                        return item;
                    })
                );
            });
            
        });

        // Cleanup WebSocket subscriptions on component unmount
        return () => {
            stockSymbols.split(",").forEach((sym) => websocketService.unSubscribe(sym, SocketType.STOCKS));
        };
    }, []);

    return marketInfo;
};

export default useMarketData;
