import React from 'react';

interface MarketHeaderProps {
    marketHeader: any[];
}

const MarketHeader: React.FC<MarketHeaderProps> = ({ marketHeader }) => {
    return (
        <div className="right-info-part">
            <div className="right-table _w-274">
                <h1 className="thead left">S&amp;P 500 Movers</h1>
            </div>

            <div className="row-data">
                {marketHeader?.map((item, index) => (
                    <div className="info" key={index}>
                        <h1 className="t-14">{item.ticker}</h1>
                        <h1 className={`t-14 ${item.session.change > 0 ? 'light-green' : 'light-red'}`}>
                            {item.session.change}%
                        </h1>
                    </div>
                ))}
            </div>

            <div className="right-table _w-274 h-39 ctr">
                <a href="#" className="link-block w-inline-block">
                    <h1 className="thead left _14">Visit Market Data Center</h1>
                    <img
                        src="https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66de848ef3500ac59bbcdd0e_Vector%20(40).svg"
                        loading="lazy"
                        alt=""
                    />
                </a>
            </div>
        </div>
    );
};

export default MarketHeader;
