
import React, { useEffect, useState } from 'react';
import { endpoints } from '../constants/environments';
import { fetchData } from '../apis/Apis';
import { MainDataProps } from '../App';
import { TradeData } from '../types/chatinterface';
import  websocketService, { SocketType }  from '../sockets/polygon';

const CompanyDataHeader: React.FC<MainDataProps> = ({ stock }) => {
    const [responseData, setResponseData] = useState<TradeData | null>(null);
    const [previousPrice, setPreviousPrice] = useState<any>(null); 

    const storeInLocalStorage = (newData: string | null | undefined) => {
        if (!newData) {
            return;
        }
    
        const storedData = localStorage.getItem('lastTwoTradeData');
        let tradeDataArray: string[] = storedData ? JSON.parse(storedData) : [];
        if (newData === tradeDataArray[tradeDataArray.length - 1]) {
            return;
        }
    
        tradeDataArray.push(newData);
    
        if (tradeDataArray.length > 5) {  // Change 3 to 5 to store up to 5 items
            tradeDataArray = tradeDataArray.slice(-5);
        }
    
        localStorage.setItem('lastTwoTradeData', JSON.stringify(tradeDataArray));
    };
    

    const getTradeDataCH = async (ticker: string) => {
        try {
            const response = await fetchData(`${endpoints.GET_LAST_TRADE_DATA}/${ticker}`);
            const trade: TradeData = response.result;
            
            setResponseData({
                ...trade,
                latestPrice:parseFloat(trade.latestPrice.toFixed(2))
            });
            setPreviousPrice(trade.closePrice)

            storeInLocalStorage(ticker);
        } catch (error) {
            console.error('Error fetching trade data', error);
        }
    };

    useEffect(() => {
        if (previousPrice !== null) {
            const handleTradeData = (socketData: any) => {
                if (socketData.ev === 'FMV') {
                    const currentPrice = socketData.fmv;
    
                    setResponseData((prev: any) => {
                        const previousData = prev || {
                            latestPrice: undefined,
                            absoluteChange: undefined,
                            percentageChange: undefined,
                            openPrice: undefined,
                            closePrice: undefined,
                            lastUpdateMarket: undefined,
                            index: undefined,
                            exchange: undefined,
                        };
    
                        const absoluteChange: any = (currentPrice - previousPrice).toFixed(2);
                        const percentageChange =
                            previousPrice !== 0
                                ? ((absoluteChange / previousPrice) * 100).toFixed(2)
                                : undefined;
                        return {
                            ...previousData,
                            latestPrice: currentPrice,
                            absoluteChange: absoluteChange,
                            percentageChange: percentageChange,
                        };
                    });
                }
            };
            if (stock) {
                websocketService.subscribe(stock, SocketType.STOCKS);
                websocketService.onMessage(SocketType.STOCKS,stock, handleTradeData);
            }
    
            return () => {
                if (stock) {
                }
                websocketService.disconnect(SocketType.STOCKS); 
            };
        }
    }, [stock, previousPrice]);
    

    useEffect(() => {
        if (stock) {
            getTradeDataCH(stock);
        }
    }, [stock]);

    return (
        <div>
            <div className="stock-info">
                <div className="left-inside _w-320">
                    <div className="bar">
                        <h1 className="stock_name f-40 mb-23">{responseData?.companyName}</h1> 
                        <div className="btn-area">
                            <img
                                src="https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66dad4a5230fe2d665915404_bi_plus.svg"
                                loading="lazy"
                                alt="Follow Icon"
                                className="image-3"
                            />
                            <a href="#" className="btn_w-109 gradient_self text-small w-button">Follow</a>
                        </div>
                    </div>
                    <div className="f-20">{responseData?.exchange} (USD) · Market {responseData?.index}</div>
                    <div className="small-text mt-10 left-inside">
                        Last Updated: {responseData?.lastUpdateMarket || '---'}
                    </div>
                </div>
                <div className="right_inside">
                    <div className="top-area">
                        <h1 className="currency f-32">$</h1>
                        <h1 className="amount f-72">{responseData?.latestPrice !== undefined ? responseData?.latestPrice : '---'}</h1>
                    </div>
                    <div className="bottom_bar">
                        {responseData?.absoluteChange !== undefined && (
                            <img
                                src={
                                    responseData.absoluteChange >= 0
                                        ? "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da92317caf51c5508aa041_Polygon%202%20(1).svg"
                                        : "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da97be2f98a346093a82b1_Polygon%201.svg"
                                }
                                loading="lazy"
                                alt="Price Change Icon"
                            />
                        )}
                        <h1 className={`f-24 ${responseData?.absoluteChange !== undefined && responseData?.absoluteChange >= 0 ? 'green' : 'red'}`}>
                            {responseData?.percentageChange !== undefined
                                ? `${responseData?.absoluteChange >= 0 ? '+' : ''}${responseData?.absoluteChange} (${responseData?.percentageChange}%)`
                                : '---'}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyDataHeader;
