import React, { useEffect, useState, useCallback, useRef } from 'react';
import { fetchData } from '../apis/Apis';
import { endpoints } from '../constants/environments';

interface TradeItem {
  ticker: string;
  name: string;
  priceChange: number;
  percentageChange: string;
  URL: string;
}

const Header = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [responseData, setResponseData] = useState<TradeItem[]>([]);
  const [error, setError] = useState<string | null>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null); 

  const toggleDropdown = () => {
    setIsDropdownVisible(prevState => !prevState);
  };

  const getSearchQueryData = useCallback(async (ticker: string) => {
    if (!ticker) return;
    try {
      const response: any = await fetchData(`${endpoints.Search_Data}/${ticker}`);
      setResponseData(response);
      setError(null);
    } catch (error) {
      console.error('Error fetching trade data', error);
      setError('Error fetching data. Please try again.');
    }
  }, []);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchQuery) {
        getSearchQueryData(searchQuery);
      } else {
        setResponseData([]);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery, getSearchQueryData]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setResponseData([]);
  };

  const handleTickerClick = (ticker: string) => {
    setIsDropdownVisible(false);
    const event = new CustomEvent('tickerSelected', {
      detail: { ticker },
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchResultsRef.current && !searchResultsRef.current.contains(event.target as Node)) {
        setResponseData([]); // Clear the search results
      }
    };
  
    if (searchResultsRef.current) {
      document.addEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchResultsRef]);
  

  return (
    <div className="header-search">
      <img
        onClick={toggleDropdown}
        src={isDropdownVisible 
          ? 'https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66f67b36eef2b187bc812601_Group%201000005916%20(1)%20(1).svg' 
          : 'https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da8407b7158c69926abcb1_Search%20(3).svg'}
        loading="lazy"
        alt="search-icon"
        className="search-icon"
      />

      {isDropdownVisible && (
        <div className="header-search-bar">
          <div className="p-relative">
            <input
              type="text"
              placeholder="Search.."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <div className="clear-btn">
              <button onClick={clearSearch}>Clear</button>
            </div>
          </div>
          {error && <p className="error-message">{error}</p>}
          {searchQuery ? (
  responseData.length > 0 ? (
    <div className="search-result-data" ref={searchResultsRef}>
      <table>
        <tbody>
          {responseData.map((item, index) => (
            <tr key={index} className="border">
              <td>
                <div className={`line ${Number(item.percentageChange) > 0 ? 'green' : 'red'}`}></div>
              </td>
              <td>
                <a href={item.URL} onClick={() => handleTickerClick(item.ticker)}>
                  <span className="symbol" data-ph-capture-attribute-search-ticker={item.ticker}>{item.ticker}</span>
                  <h6 className="company-name">{item.name}</h6>
                </a>
              </td>
              <td className="text-end">
                <span className="rate">{item.priceChange}</span>
                <div className="flex">
                  <img
                    src={Number(item.percentageChange) > 0 
                      ? 'https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da92317caf51c5508aa041_Polygon%202%20(1).svg' 
                      : 'https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da97be2f98a346093a82b1_Polygon%201.svg'}
                    loading="lazy"
                    alt="percentage-icon"
                    className="up-icon"
                  />
                  <h6 className={`percentage ${Number(item.percentageChange) > 0 ? 'green' : 'red'}`}>
                    {item.percentageChange}%
                  </h6>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p>No results found</p>
  )
) : null}


        </div>
      )}
    </div>
  );
};

export default Header;
