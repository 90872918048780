import { useState, useEffect, useCallback } from 'react';
import { calculateChange } from '../services/calcculateChange';
import { fetchData } from '../apis/Apis';
import { endpoints } from '../constants/environments';
import websocketService, { SocketType } from '../sockets/polygon';

const useMarketHeaderData = () => {
    const [marketHeader, setMarketHeader] = useState<any[]>([]);

    // Fetch market header data
    const fetchMarketHeader = useCallback(async () => {
        try {
            const response = await fetchData(`${endpoints.MARKET_HEADER}`);
            setMarketHeader(response.result);
        } catch (error) {
            console.error('Error fetching market index data:', error);
        }
    }, []);

    useEffect(() => {
        const stocks = 'VST,HPE,CEG,GPN,AMGN,GM';

        // Initial fetch of market header data
        fetchMarketHeader();

        // Subscribe to WebSocket updates for each stock
        stocks.split(",").forEach((sym) => {
            websocketService.subscribe(sym, SocketType.STOCKS);

            websocketService.onMessage(SocketType.STOCKS, sym, (data: any) => {
                const fmv = data.fmv;

                setMarketHeader((prevData) =>
                    prevData.map((item) => {
                        if (item.ticker === data.sym) {
                            const previousClose = item.session.previous_close;
                            const { change, changePercent } = calculateChange(fmv, previousClose);
                            return {
                                ...item,
                                fmv,
                                session: {
                                    ...item.session,
                                    price: fmv,
                                    change: parseFloat(change.toFixed(2)),
                                    change_percent: parseFloat(changePercent.toFixed(2)),
                                },
                            };
                        }
                        return item;
                    })
                );
            });
        });

        // Clean up WebSocket subscriptions on unmount
        return () => {
            stocks.split(",").forEach((sym) => websocketService.unSubscribe(sym, SocketType.STOCKS));
        };
    }, [fetchMarketHeader]);

    return marketHeader;
};

export default useMarketHeaderData;
