interface ChangeData {
    change: number;
    changePercent: number;
}

export const calculateChange = (fmv: number, previousClose: number): ChangeData => {
    const change = fmv - previousClose;
    const changePercent: number = ((change / previousClose) * 100)
    return { change, changePercent };
};

