/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { APP_LOGIN_URL, endpoints, XANO_ENDPOINT } from '../constants/environments';
import { fetchData, postData } from '../apis/Apis';
import { MainDataProps } from '../App';
import { TradeData } from '../types/chatinterface';
import  websocketService, { SocketType }  from '../sockets/polygon';

const CompanyDataHeader = ({ stock,user_id,login_page_url }:any) => {
    const [responseData, setResponseData] = useState<TradeData | null>(null);
    const [previousPrice, setPreviousPrice] = useState<any>(null); 
   const  [isFollowedStock, setIsFollowedStock] = useState<boolean>(false);
   const [isFollowedStockId , setIsFollowedStockId] = useState<any>(null)

    const storeInLocalStorage = (newData: string | null | undefined) => {
        if (!newData) {
            return;
        }
    
        const storedData = localStorage.getItem('lastTwoTradeData');
        let tradeDataArray: string[] = storedData ? JSON.parse(storedData) : [];
        if (newData === tradeDataArray[tradeDataArray.length - 1]) {
            return;
        }
    
        tradeDataArray.push(newData);
    
        if (tradeDataArray.length > 5) {  // Change 3 to 5 to store up to 5 items
            tradeDataArray = tradeDataArray.slice(-5);
        }
    
        localStorage.setItem('lastTwoTradeData', JSON.stringify(tradeDataArray));
    };
    

    const getTradeDataCH = async (ticker: string) => {
        try {
            const response = await fetchData(`${endpoints.GET_LAST_TRADE_DATA}/${ticker}`);
            const trade: TradeData = response.result;
            
            setResponseData({
                ...trade,
                latestPrice:parseFloat(trade.latestPrice.toFixed(2))
            });
            setPreviousPrice(trade.closePrice)

            storeInLocalStorage(ticker);
        } catch (error) {
            console.error('Error fetching trade data', error);
        }
    };

    useEffect(() => {
        if (previousPrice !== null) {
            const handleTradeData = (socketData: any) => {
                if (socketData.ev === 'FMV') {
                    const currentPrice = socketData.fmv;
    
                    setResponseData((prev: any) => {
                        const previousData = prev || {
                            latestPrice: undefined,
                            absoluteChange: undefined,
                            percentageChange: undefined,
                            openPrice: undefined,
                            closePrice: undefined,
                            lastUpdateMarket: undefined,
                            index: undefined,
                            exchange: undefined,
                        };
    
                        const absoluteChange: any = (currentPrice - previousPrice).toFixed(2);
                        const percentageChange =
                            previousPrice !== 0
                                ? ((absoluteChange / previousPrice) * 100).toFixed(2)
                                : undefined;
                        return {
                            ...previousData,
                            latestPrice: currentPrice,
                            absoluteChange: absoluteChange,
                            percentageChange: percentageChange,
                        };
                    });
                }
            };
            if (stock) {
                websocketService.subscribe(stock, SocketType.STOCKS);
                websocketService.onMessage(SocketType.STOCKS,stock, handleTradeData);
            }
    
            return () => {
                if (stock) {
                }
                websocketService.disconnect(SocketType.STOCKS); 
            };
        }
    }, [stock, previousPrice]);
    
    const handleClick = async (stock:any,user_id:any) => {
        let body:any ={
          user_id: user_id,
          sym: stock
        };
        let url = XANO_ENDPOINT.FOLLOW_STOCK;
        if(isFollowedStock === true){
          url = XANO_ENDPOINT.UNFOLLOW_STOCK;
          body={
            id : isFollowedStockId
          };
          const confirmed = window.confirm("Are you sure you want to unfollow this stock?");
          if (!confirmed) {
            return false;
          } 
        }
        postData(url,body).then(response =>{
          setIsFollowedStock(!isFollowedStock);
          document.dispatchEvent(new CustomEvent('watch-list-ticker-update'));
          // console.log(response);
          if(response.followed_id){
            setIsFollowedStockId(response.followed_id);
          }
        });
        
    };

    const geStockFollowedStatus = async ( stock:any , user_id:any)=>{
      const followStockData:any = await fetchData(XANO_ENDPOINT.GET_STOCK +'?user_id=' + user_id + '&sym='+stock);
      // console.log(followStockData ,"====>>>")
      followStockData.forEach((item:any )=> {
        if(item.stock_id === stock){
          setIsFollowedStock(true);
          setIsFollowedStockId(item.id);
        }
      });
    }

    useEffect(() => {
        if(stock && user_id){
          geStockFollowedStatus(stock, user_id);
        }
    },[stock,user_id]);
    useEffect(() => {
        if (stock) {
            getTradeDataCH(stock);
        }
    }, [stock]);

    return (
        <div>
            <div className={`stock-info ${isFollowedStock === true ?'flwed': 'flw'}`}>
                <div className="left-inside _w-320">
                    <div className="bar">
                        <h1 className="stock_name f-40 mb-23">{responseData?.companyName}</h1> 
                        <div className="btn-area">
                            <img
                                src=  {isFollowedStock === true ? "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/6749ae26594d7c46f4e0c5d0_tick.svg" : "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66dad4a5230fe2d665915404_bi_plus.svg"} 
                                loading="lazy"
                                alt="Follow Icon"
                                className="image-3"
                            />
                            <a
                              href={user_id ?  "#" : (login_page_url) ? login_page_url : APP_LOGIN_URL } 
                              onClick={() => handleClick(stock, user_id)}
                              className="btn_w-109 gradient_self text-small w-button">
                                {isFollowedStock === true ? " Following " : " Follow "}</a>
                        </div>
                    </div>      
                    <div className="f-20">{responseData?.exchange} (USD) · Market {responseData?.index}</div>
                    <div className="small-text mt-10 left-inside">
                        Last Updated: {responseData?.lastUpdateMarket || '---'}
                    </div>
                </div>
                <div className="right_inside">
                    <div className="top-area">
                        <h1 className="currency f-32">$</h1>
                        <h1 className="amount f-72">{responseData?.latestPrice !== undefined ? responseData?.latestPrice : '---'}</h1>
                    </div>
                    <div className="bottom_bar">
                        {responseData?.absoluteChange !== undefined && (
                            <img
                                src={
                                    responseData.absoluteChange >= 0
                                        ? "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da92317caf51c5508aa041_Polygon%202%20(1).svg"
                                        : "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da97be2f98a346093a82b1_Polygon%201.svg"
                                }
                                loading="lazy"
                                alt="Price Change Icon"
                            />
                        )}
                        <h1 className={`f-24 ${responseData?.absoluteChange !== undefined && responseData?.absoluteChange >= 0 ? 'green' : 'red'}`}>
                            {responseData?.percentageChange !== undefined
                                ? `${responseData?.absoluteChange >= 0 ? '+' : ''}${responseData?.absoluteChange} (${responseData?.percentageChange}%)`
                                : '---'}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyDataHeader;
