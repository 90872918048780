import React from 'react';

interface TickerProps {
    ticker: string;
    session: {
        previous_close: number;
        price: number;
        change?: number;
        change_percent?: number;
    };
    last_quote?: {
        ask: number;
    };
    activetab:string;
    isActive: boolean;
    onClick: (ticker: string) => void;
}

const Ticker: React.FC<TickerProps> = ({ ticker, session: { change , price, change_percent}, last_quote,activetab, isActive, onClick }) =>
(
    <>
    
        <div className={`td _w-132 ${isActive ? 'active' : ''}`}
        id={
         activetab === 'Fx' 
        ? 'fxtab' 
        : activetab === 'Crypto' 
        ? 'cryptotab' 
        : 'stock'
        }   
        style={{ cursor: 'pointer' }}
            onClick={() => onClick(ticker)}>

            <h1 className="td-data withborder">
                {ticker}
            </h1>
        </div>
        <div id={activetab==='Fx'? 'fxvalue':""} className="td _w-126">
            <h1 className="td-data">
                {price ? price : last_quote?.ask}
            </h1>
        </div>
        <div id={activetab==='Fx'? 'fxvalue':""} className="td _w-107">
            <h1 className={`td-data ${change && change < 0 ? 'down' : 'up'}`}>
                {change}
            </h1>
        </div>
        <div id={activetab==='Fx'? 'fxvalue':""} className="td">
            <div className="data justify-end">
                <div className={`text-block-2 ${change_percent && change_percent < 0 ? 'red' : 'green'} f-14`}>
                    {change_percent}
                </div>
                <img
                    src={
                        change_percent && change_percent < 0
                            ? "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da97be2f98a346093a82b1_Polygon%201.svg"
                            : "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da92317caf51c5508aa041_Polygon%202%20(1).svg"
                    }
                    loading="lazy"
                    alt=""
                    className={change_percent && change_percent < 0 ? 'downside down-up-icon' : 'upside down-up-icon'}
                />
            </div>
        </div>
    </>)
export default Ticker;
