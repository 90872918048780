import React from 'react'
import Tabs from './Tabs'
import Overview from '../component/Overview'
import CompanyDataHeader from '../component/CompanyDataHeader'
import Header from '../component/Header'
import { MainDataProps } from '../App'
import QuotesSearch from '../component/QuotesSearch'


const Main: React.FC<MainDataProps> = ({ stock }) => {
  return (
    <div>
      <Header/>
        <CompanyDataHeader stock={stock}/>
        <Tabs stock={stock}/>
        <Overview stock={stock}/> 
    </div>
  )
}

export default Main