import React, { useState } from 'react';
import ReleatedNews from '../component/ReleatedNews';
import Summary from '../component/Summary';
import CompanyInfo from '../component/CompanyInfo';
import Financials from '../component/Financials';
import { MainDataProps } from '../App';

const Tabs: React.FC<MainDataProps> = ({ stock }) => {
  const [activeTab, setActiveTab] = useState("summary");

  const handleClick = (value: string) => {
    setActiveTab(value);
  };

  return (
    <div>
      {/* Tab Menu */}
      <div className="tabs-menu w-tab-menu" role="tablist">
        <a
          className={`bg-white w-inline-block w-tab-link ${activeTab === 'summary' ? 'w--current' : ''}`}
          onClick={() => handleClick('summary')}
          role="tab"
          aria-selected={activeTab === 'summary'}
        >
          <div className="f-20 mid">Summary</div>
        </a>
        <a
          className={`bg-white w-inline-block w-tab-link ${activeTab === 'releated-news' ? 'w--current' : ''}`}
          onClick={() => handleClick('releated-news')}
          role="tab"
          aria-selected={activeTab === 'releated-news'}
        >
          <div className="f-20 normal">Related News</div>
        </a>
        <a
          className={`bg-white w-inline-block w-tab-link ${activeTab === 'company-info' ? 'w--current' : ''}`}
          onClick={() => handleClick('company-info')}
          role="tab"
          aria-selected={activeTab === 'company-info'}
        >
          <div className="f-20 normal">Company Info</div>
        </a>
        <a
          className={`bg-white w-inline-block w-tab-link ${activeTab === 'financials' ? 'w--current' : ''}`}
          onClick={() => handleClick('financials')}
          role="tab"
          aria-selected={activeTab === 'financials'}
        >
          <div className="f-20 normal">Financials</div>
        </a>
      </div>

      <div className="tab-content">
        {activeTab === 'summary' && <Summary stock={stock}/>}
        {activeTab === 'releated-news' && <ReleatedNews stock={stock}/>}
        {activeTab === 'company-info' && <CompanyInfo stock={stock}/>}
        {activeTab === 'financials' && <Financials stock={stock}/>}
      </div>
    </div>
  );
};

export default Tabs;