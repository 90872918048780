import React from 'react'
import Tabs from './Tabs'
import Overview from '../component/Overview'
import CompanyDataHeader from '../component/CompanyDataHeader'
import Header from '../component/Header'
import { MainDataProps } from '../App'
import QuotesSearch from '../component/QuotesSearch'


const Main = ({ stock,user_id,login_page_url }:any) => {
  return (
    <div>
      <Header/>
        <CompanyDataHeader stock={stock} user_id={user_id} login_page_url={login_page_url} />
        <Tabs stock={stock} />
        <Overview stock={stock}/> 
    </div>
  )
}

export default Main