import React, { useEffect, useMemo, useState } from 'react';
import { Suspense } from 'react';
import { DotLoader } from 'react-spinners';
import MarqueeHeader from './MarqueueHeader';
import TabsComponent from './Tabs';
import useMarketData from '../../hooks/useMarketData';


const MarketData = ({ isDropdown }: any) => {

    const marketInfo = useMarketData();
    const memoizedMarketInfo = useMemo(() => marketInfo, [marketInfo]);

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const handleDropDownClick = () => {
        setIsDropdownVisible((prev: any) => !prev);
    };
    useEffect(() => {
        if (isDropdown == 'true') {
            setIsDropdownVisible(true)
        }
    }, [isDropdown]);

    return (
        <Suspense fallback={<DotLoader loading />}>

            <div className="data-row">
                <header />

                <div className="w-layout-blockcontainer _w-1360 w-container">
                    <div className="row left full-size min-height-72">
                        <div data-w-id="466f3588-3b58-c762-77b7-b328de4722bf" className="cst-drop">
                            <div>Market Data</div>
                            <img
                                src="https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66de8fc02ef2b2f5c88202be_Vector%201%20(2).svg"
                                loading="lazy"
                                alt=""
                                className="arrow-down"
                                onClick={handleDropDownClick}
                                style={{ transform: isDropdownVisible ? 'rotate(180deg)' : 'rotate(0deg)' }}
                            />
                        </div>
                        <MarqueeHeader data={memoizedMarketInfo} />

                    </div>
                </div>

                <TabsComponent
                    isDropdownVisible={isDropdownVisible}
                />
            </div>
        </Suspense>
    );
};

export default MarketData;
