import React, { useEffect, useState } from 'react';
import { fetchData } from '../apis/Apis';
import { endpoints, SERVER_URL } from '../constants/environments';
import { MainDataProps } from '../App';

interface TickerData {
    ticker: string;
    session: {
        price?: number;
        change?: number;
        change_percent?: number;
    };
}

const RelatedTicker: React.FC<MainDataProps> = ({ stock }) => {
    const [relatedTicker, setRelatedTicker] = useState<TickerData[]>([]);

    const getData = async (ticker: string) => {
        try {
            const response: any = await fetchData(`${endpoints.RELEATED_TICKER}/${ticker}`);

            // Filter out items with empty or undefined session values
            const filteredData = response?.result?.relatedTickersSnapshot
                ?.map((item: any) => item.results[0])
                .filter((ticker: TickerData) => ticker.session && ticker.session.price != null)
                .slice(0, 5);;

            setRelatedTicker(filteredData || []);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        if (stock) {
            getData(stock);
        }
    }, [stock]);

    const handleClick = (tickerName: string) => {
        window.location.href = `${SERVER_URL}${tickerName}`;
    };

    return (
        <div className="widget p-32">
            <h1 className="f-20 semi">Related Tickers</h1>
            {relatedTicker.length > 0 ? (
                relatedTicker.map((ticker, index) => (
                    <div key={index} className="dark-box _w-316 mt-24">
                        <div>
                            <h4
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleClick(ticker.ticker)}
                                className="stock_name f-16"
                            >
                                {ticker.ticker}
                            </h4>
                            <div className="f-16 white">
                                {ticker.session?.price ? `${ticker.session.price} USD` : "N/A"}
                            </div>
                        </div>
                        <div className="data">
                            <img
                                src={
                                    ticker.session?.change_percent && ticker.session.change_percent >= 0
                                        ? "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da92317caf51c5508aa041_Polygon%202%20(1).svg"
                                        : "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da97be2f98a346093a82b1_Polygon%201.svg"
                                }
                                loading="lazy"
                                alt={ticker.session?.change_percent && ticker.session.change_percent >= 0 ? "up" : "down"}
                                className={ticker.session?.change_percent && ticker.session.change_percent >= 0 ? "upside" : "downside"}
                            />
                            <div className={`text-block-2 ${ticker.session?.change_percent && ticker.session.change_percent >= 0 ? 'green' : 'red'}`}>
                                {ticker.session?.change_percent != null ? `${ticker.session.change_percent}%` : "0%"}
                            </div>
                            <div className="f-16 white">
                                {ticker.session?.change != null ? `${ticker.session.change} USD` : "No Change"}
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div>No Related tickers.</div>
            )}
        </div>
    );
};

export default RelatedTicker;
