import React, { useEffect, useState } from 'react'
import { endpoints } from '../constants/environments';
import { fetchData } from '../apis/Apis';
import { MainDataProps } from '../App';


const ReleatedNews: React.FC<MainDataProps> = ({ stock }) => {
  const [relatedNews, setRelatedNews] = useState<any[]>([]);

const getData = async (ticker:string) => {
    try {
      const response = await fetchData(`${endpoints.GET_COMPANY_DATA}/${ticker}`);
      setRelatedNews(response.result.relatedNews);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    getData(stock);   
  }, [stock]);

  return (  
    // <div>
    //   {relatedNews && relatedNews.map((newsItem, index) => (
    //     <div key={index}>
    //       <a href={newsItem.article_url} target="_blank" > <h3>{newsItem.title}</h3></a>
    //         <img height={200} src={newsItem.image_url}></img>
    //       <p>{newsItem.published_utc} by {newsItem.author}</p>            
    //     </div>
    //   ))}
    // </div>

    <div className="container">
      <div className="row rel-car-row">
      {relatedNews && relatedNews.map((newsItem, index) => (
        <div key={index} className="col-md-4"> {/* Adjust the column size as needed */}
          <div className="rel-card">
            <img height={200} src={newsItem.image_url} alt={newsItem.title} className="mb-3" />

            <a href={newsItem.article_url} target="_blank" rel="noopener noreferrer">
              <h3>{newsItem.title}</h3>
            </a>     

            <div className="rel-info d-flex align-items-center justify-content-between">
              <p>{newsItem.published_utc}</p>
              <h6>by {newsItem.author}</h6>
            </div>       
          </div>
        </div>
      ))}
    </div>
    </div>
);
};

export default ReleatedNews