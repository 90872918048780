import React, { useEffect, useState } from 'react';
import { fetchData, postData } from '../apis/Apis';
import { endpoints, SERVER_URL, XANO_ENDPOINT } from '../constants/environments';

interface TradeData {
  tickerName: string;
  latestPrice: number;
  percentageChange: string;
}

const RecentlyViewTicker: React.FC<any> = ({tickers,type,action}) => {

  const [recentlyViewed, setRecentlyViewed] = useState<string[]>([]);
  const [responseData, setResponseData] = useState<TradeData[]>([]);
  const [userTickers, setUserTickers] = useState<any>([]);
  console.log(action);
  const getRecentlyViewedTickers = () => {
    const storedData = localStorage.getItem('lastTwoTradeData');
    if (storedData) {
      const parsedData: string[] = JSON.parse(storedData);
      if (parsedData.length === 1) {
        setRecentlyViewed([parsedData[0]]); 
      } else {
        setRecentlyViewed(parsedData.slice(-5).reverse());  // Retrieve and reverse the last 5 items
      }
    }
};

  document.addEventListener("add-user-ticker", (event:any) => {
    setUserTickers(event.detail);
  });

  const getTradeData = async (ticker: string) => {
    try {
      const response = await fetchData(`${endpoints.GET_LAST_TRADE_DATA}/${ticker}`);
      const trade: TradeData = response.result;
      setResponseData((prevData) => [...prevData, trade]); 
    } catch (error) {
      console.error('Error fetching trade data', error);
    }
  };

  useEffect(() => {
    if(type === 'recently'){
      getRecentlyViewedTickers();
    }else{
    
      if(tickers){
        let tickersArr=tickers.split(',');
        setResponseData([]);
        tickersArr.forEach((ticker:string) => {
          getTradeData(ticker); 
        });
      }else{
        setResponseData([]);
      }
    }
    
  },[tickers,type]);

  const remove = async (ticker_name:string)=>{
    const confirmed = window.confirm("Are you sure you want to unfollow this stock?");
    if (!confirmed) {
      return false;
    } 
    if(userTickers.length > 0) {
      let url = XANO_ENDPOINT.UNFOLLOW_STOCK;
      let isFollowedStockId=userTickers.filter((item:any) => item.stock_id === ticker_name)[0].id;
      let body={
        id : isFollowedStockId
      };

      postData(url,body).then(response =>{
        const index = responseData.findIndex(item => item.tickerName === ticker_name);
        if(index !== -1){
          removeItemAtIndex(index)
        }
      });
      
      
    }else{
      alert('Something went wrong');
    }
   
  }

  const removeItemAtIndex = (index: number) => {
    setResponseData(prevData => prevData.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (recentlyViewed.length > 0) {
      recentlyViewed.forEach((ticker) => {
        getTradeData(ticker); 
      });
    }
  }, [recentlyViewed]);

  const handleClick = (tickerName: string) => {
    window.location.href =  `${SERVER_URL}${tickerName}`;
  };
  

  return (
    <div>
          <div className="widget p-32">
            <h1 className="f-20 semi">{type === 'recently' ? 'Recently Viewed Tickers' : 'Your Watchlist'}</h1>
            {responseData.length > 0 ? (
              responseData.map((ticker, index) => (
                <div key={index} className={`dark-box _w-316 mt-24  ${action ? 'action-ad' : ''}`}>
                  <div>
                  <h4   style={{ cursor: 'pointer' }}
                    onClick={() => handleClick(ticker?.tickerName)} className="stock_name f-16">
                      {ticker?.tickerName}
                    </h4>
                    <div className="f-16 white">{ticker?.latestPrice} USD</div>
                  </div>
                  <div className="data">
                    <img
                      src={
                        parseFloat(ticker?.percentageChange) >= 0
                          ? "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da92317caf51c5508aa041_Polygon%202%20(1).svg"
                          : "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da97be2f98a346093a82b1_Polygon%201.svg"
                      }
                      loading="lazy"
                      alt={parseFloat(ticker?.percentageChange) >= 0 ? "up" : "down"}
                      className={parseFloat(ticker?.percentageChange) >= 0 ? "upside" : "downside"}
                    />
                    <div className={`text-block-2 ${parseFloat(ticker?.percentageChange) >= 0 ? 'green' : 'red'}`}>
                      {ticker?.percentageChange}
                    </div>
                  </div>
                  {action && (
                  <div className="remove unfollow">
                      <i
                        onClick={() => remove(ticker?.tickerName)}
                        className="icon-remove"
                        aria-hidden="true"
                      >
                        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_671_2139)">
                          <path d="M50 0C22.4289 0 0 22.4289 0 50C0 77.5711 22.4289 100 50 100C77.5711 100 100 77.5711 100 50C100 22.4289 77.5711 0 50 0Z"/>
                          <path d="M68.4144 62.5203C70.0433 64.1499 70.0433 66.7828 68.4144 68.4125C67.6019 69.225 66.5353 69.6332 65.4679 69.6332C64.4013 69.6332 63.3348 69.225 62.5222 68.4125L50.0016 55.8911L37.481 68.4125C36.6685 69.225 35.6019 69.6332 34.5353 69.6332C33.468 69.6332 32.4014 69.225 31.5888 68.4125C29.96 66.7828 29.96 64.1499 31.5888 62.5203L44.1102 49.9997L31.5888 37.4791C29.96 35.8494 29.96 33.2165 31.5888 31.5869C33.2185 29.958 35.8514 29.958 37.481 31.5869L50.0016 44.1083L62.5222 31.5869C64.1519 29.958 66.7848 29.958 68.4144 31.5869C70.0433 33.2165 70.0433 35.8494 68.4144 37.4791L55.893 49.9997L68.4144 62.5203Z" fill="#FAFAFA"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_671_2139">
                          <rect width="100" height="100" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>

                        </i>
  
                  </div>
                  )}
                </div>
              ))
            ) : (
              <div>{type === 'recently' ? 'No recently viewed tickers.' : 'No tickers found'}</div>
            )}
          </div>

         

        
        </div>
    
  );
};

export default RecentlyViewTicker;
