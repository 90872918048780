export const BASE_URI = process.env.NODE_ENV === "production" ?
    'https://data.deepstreet.io' :
    'https://data.deepstreet.io';

export const APP_LOGIN_URL = 'https://deepstreet-io.webflow.io/log-in';

export const XANO_API_URL = 'https://x5if-rotv-ir4m.n7d.xano.io/api:EHmJZhGO/';

export const XANO_ENDPOINT={
    GET_STOCK: XANO_API_URL + "get-stocks",
    FOLLOW_STOCK: XANO_API_URL + "follow_stock",
    UNFOLLOW_STOCK: XANO_API_URL + "un-follow"
}
export const BASE_URL =
    process.env.NODE_ENV === "production" ?
        `${BASE_URI}/api` :
        `${BASE_URI}/api`;


export const endpoints = {
    GET_COMPANY_DATA: `${BASE_URL}/company`,
    GET_STOCK_DATA: `${BASE_URL}/stock`,
    GET_LAST_TRADE_DATA: `${BASE_URL}/trade`,
    OVERVIEW_DATA: `${BASE_URL}/overview`,
    FINANCE_DATA: `${BASE_URL}/finance`,
    MARKET_DATA: `${BASE_URL}/marketData`,
    MARKET_INDEX: `${BASE_URL}/marketIndex`,
    MARKET_HEADER: `${BASE_URL}/marketHeader/sp`,
    Search_Data: `${BASE_URL}/search`,
    RELEATED_TICKER:`${BASE_URL}/releatedTicker`,
    SDK_DATA:`${BASE_URL}/sdkData`
}

export const SERVER_URL='/stocks?stock='
