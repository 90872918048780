import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { StockChartProps } from "../types/chatinterface";
import websocketService, { SocketType } from '../sockets/polygon';
import moment from 'moment-timezone';
import useMarketHours from '../hooks/useMarketHours';
import { timezone } from "../constants/timezone";

const CandleStickChartWithRealTimeData: React.FC<StockChartProps> = ({ dates, name, timeRange }) => {
    const { effectiveMarketOpenTime, effectiveMarketCloseTime } = useMarketHours();
    
    const [series, setSeries] = useState([
        {
            data: dates // Initialize with the provided dates
        }
    ]);

    const chartOptions: ApexOptions = {
        chart: {
            type: 'candlestick',
            height: 350,
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            zoom: {
                enabled: true
            },
            toolbar: {
                show: true
            }
        },

        xaxis: {
            type: 'datetime',
            min: effectiveMarketOpenTime as number,
            max: effectiveMarketCloseTime as number,
            tickAmount: 12,
            labels: {
                formatter: (value: any) => moment(value).tz(timezone).format('hh:mm a'),
                datetimeUTC: false
            },
            tooltip: {
                enabled: true,
                formatter: (value: any) => moment(value).tz(timezone).format('hh:mm a'),
            },
        },
        yaxis: {},
        dataLabels: {
            enabled: false,
        },
        colors: ['#3E1A79', '#7345FF'],
        stroke: {
            width: 1,
            curve: 'smooth',
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#7345FF',
                    downward: '#3E1A79',
                }
            }
        },
        tooltip: {
            x: {
                formatter: (value: any) => moment(value).tz(timezone).format('hh:mm a'),
            },
            style: {
            }
        }
    };

    useEffect(() => {
        const handleStockUpdate = (response: any) => {
            if (response && response.s !== undefined && response.c !== undefined) {
                const newPoint = {
                    x: response.s,
                    y: [
                        parseFloat(response.o),
                        parseFloat(response.h),
                        parseFloat(response.l),
                        parseFloat(response.c)
                    ]
                };
                pushNewData(newPoint);
            }
        };

        websocketService.onMessage(SocketType.STOCKS, name, handleStockUpdate, true);

        // Cleanup the subscription on component unmount

    }, [timeRange, name]);

    const pushNewData = (data: any) => {
        setSeries((prevSeries) => [
            {
                data: [
                    ...prevSeries[0].data,
                    data
                ]
            }
        ]);
    };

    return (
        <div>
            <ReactApexChart
                options={chartOptions}
                series={series}
                type="candlestick"
                height={463}
                width="100%"
            />
        </div>
    );
};

export default CandleStickChartWithRealTimeData;
