import React, { useContext, useEffect, useState } from 'react';
import { StockDataContext } from '../apis/StockDataContext';
import { DotLoader } from 'react-spinners';
import { MainDataProps } from '../App';
import websocketService, { SocketType } from '../sockets/polygon';
import moment from 'moment';
import { formats } from './formats';
import { timeOptions, timeRangeMap } from './timeRange';
// import CandleChart from '../apex-chats/CandleStick';
import CandleStickChartWithRealTimeData from '../apex-chats/CandleStickChartWithRealTimeData';
import CandleChart from '../apex-chats/candleStick';

export interface ChartData {
  x: number;
  y: number | any;
}
const Summary: React.FC<MainDataProps> = ({ stock }) => {
  const stockDataContext = useContext(StockDataContext);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [timeRange, setTimeRange] = useState(timeRangeMap[0]);
  const [refreshChartData, setRefreshChartData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedRange, setSelectedRange] = useState(timeRange.range);
  const [availableOptions, setAvailableOptions] = useState(timeOptions);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (stockDataContext?.fetchData) {
        await stockDataContext.fetchData(stock, timeRange);
      }
      setLoading(false);
    };
if(stock){
  fetchData();

}
  }, [timeRange, stock]);

  useEffect(() => {
    if (stockDataContext?.data) {
      const groups = new Set();
      const mappedData = stockDataContext.data.map((item) => {
        const tempDate = moment(item.t);
        let formattedDate: string;
  
        if (timeRange.value === "1day") {
          formattedDate = item.t as any;
        } 
        else if (timeRange.value === '5day' && timeRange.ogTimeSpan === 'minute') {
          formattedDate = tempDate.format(formats.minute); 
        } 
        else {
          switch (timeRange.ogTimeSpan) {
            case 'minute':
              formattedDate = item.t as any;
              break;
            case 'hour':
              formattedDate = tempDate.format(formats.hour);
              break;
            case 'day':
              formattedDate = tempDate.format(formats.day);
              break;
            case 'week':
              formattedDate = tempDate.format(formats.week);
              break;
            case 'month':
              formattedDate = tempDate.format(formats.month);
              break;
            default:
              formattedDate = tempDate.format(formats.default);
          }
        }
        
        groups.add(formattedDate);
        return {
          x: formattedDate,
          y: [item.o, item.h, item.l, item.c],
        };
      });
  
      setCategories(Array.from(groups));
      setRefreshChartData(true);
      setFormattedData(mappedData);
    }
  }, [stockDataContext?.data, timeRange]);
  

  useEffect(() => {
    if (stock) {
      websocketService.subscribe(stock, SocketType.STOCKS, 'aggregate');
    }
    return () => {
      websocketService.disconnect(SocketType.STOCKS);
    };
  }, [stock]);
  

  const handleTabClick = (timeRangeItem: any) => {
    setTimeRange(timeRangeItem);
    setSelectedRange(timeRangeItem.frequency)
  };
  const updateAvailableOptions = (selectedRange: any) => {
    const updatedOptions = timeOptions.map((option) => {
      let isDisabled = false;
      switch (selectedRange.value) {
        case '1day':
          isDisabled = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'].includes(option.value);
          break;
        case '5day':
          isDisabled = ['weekly', 'monthly', 'quarterly', 'yearly'].includes(option.value);
          break;
        case '1month':
          isDisabled = !['daily', 'weekly'].includes(option.value);
          break;
        case '3month':
          isDisabled = !['daily', 'weekly', 'monthly'].includes(option.value);
          break;
        case '6months':
        case '1year':
          isDisabled = !['daily', 'weekly', 'monthly', 'quarterly'].includes(option.value);
          break;
        case '3years':
          isDisabled = !['daily', 'weekly', 'monthly', 'quarterly', 'yearly'].includes(option.value);;
          break;
        default:
          isDisabled = false;
      }

      return {
        ...option,
        disabled: isDisabled,
      };
    });

    setAvailableOptions(updatedOptions);
  };

  useEffect(() => {
    updateAvailableOptions(timeRange)

  }, [timeRange])


  const handleTimeOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTimeOption = e.target.value;

    if (['1min', '5min', '10min', '15min'].includes(selectedTimeOption)) {
      const newRangeValue = selectedTimeOption.replace('min', '');
      setSelectedRange(selectedTimeOption);
      setTimeRange((prev) => ({
        ...prev,
        range: newRangeValue,
        ogTimeSpan: 'minute',
      }));
    } else {
      let newOgTimeSpan = '';
      switch (selectedTimeOption) {
        case 'hourly':
          newOgTimeSpan = 'hour';
          break;
        case 'daily':
          newOgTimeSpan = 'day';
          break;
        case 'weekly':
          newOgTimeSpan = 'week';
          break;
        case 'monthly':
          newOgTimeSpan = 'month';
          break;
        case 'quarterly':
          newOgTimeSpan = 'quarter';
          break;
        case 'yearly':
          newOgTimeSpan = 'year';
          break;
        default:
          newOgTimeSpan = 'day';
      }

      setSelectedRange(selectedTimeOption);
      setTimeRange((prev) => ({
        ...prev,
        range: '1',
        ogTimeSpan: newOgTimeSpan,
      }));
    }
  };

  return (
    <div>
      <div className='tabs-main-heading'>
        <div className="tabs-container summary-tabs">
          <label>Chart Range</label>

          {timeRangeMap.map((timeRangeItem, index) => (
            <button
            key={`${timeRangeItem.range}-${index}`}
              className={`tab-item ${timeRange.value === timeRangeItem.value ? 'active' : ''}`}
              onClick={() => handleTabClick(timeRangeItem)}
            >
              {timeRangeItem.label}
            </button>
          ))}
        </div>

        <div className="time-options-select">
          <label>Frequency</label>
          <select value={selectedRange} onChange={handleTimeOptionChange}>
      {!availableOptions.some(option => option.value === timeRange.frequency) && (
        <option value="">{timeRange.frequency}</option>
      )}
  {availableOptions.map((option, index) => (
        <option key={`${option.value}-${index}`}  value={option.value} disabled={option.disabled}>
          {option.label}
        </option>
      ))}
    </select>



        </div>
      </div>

      {loading ? (
        <DotLoader
          style={{
            display: 'inherit',
            position: 'relative',
            width: '60px',
            height: '60px',
            animation: '2s linear 0s infinite normal none running react-spinners-DotLoader-rotate',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '40px',
          }}
        />
      ) :

        timeRange?.value === '1day'  ?

          (
            <CandleStickChartWithRealTimeData
              dates={formattedData}
              categories={categories}
              name={stock}
              onTimeRangeChange={setAvailableOptions}
              timeRange={timeRange}
              refreshChatData={refreshChartData}
              afterChangeData={setRefreshChartData}
            />
          )
          :
          (
            <CandleChart
              dates={formattedData}
              categories={categories}
              name={stock}
              onTimeRangeChange={setAvailableOptions}
              timeRange={timeRange}
              refreshChatData={refreshChartData}
              afterChangeData={setRefreshChartData}
            />
          )


      }

    </div>
  );
};

export default Summary;
