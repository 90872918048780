import './App.css';
import React, { useEffect } from 'react';
import { StockDataProvider } from './apis/StockDataContext';
import ReactDOM from 'react-dom';
import Main from './pages/Main';
import { FinancialsProvider } from './apis/FinancialsContext';
import MarketData from './component/MarketData';
import Header from './component/Header';
import RecentlyViewTicker from './component/RecentlyViewTicker';
import QuotesSearch from './component/QuotesSearch';
import ReleatedTickerr from './component/ReleatedTickerr';
import RelatedTicker from './component/ReleatedTickerr';
import StockHover from './sdk/StockHover';



export interface MainDataProps {
  stock: string; // Define the type of the stock prop
}


// Define a function to register the custom element
const defineCustomElement = (name: string, component: (attrs?: any,dropdown?:any) => JSX.Element) => {
  if (!customElements.get(name)) {  // Check if the custom element is already defined
    class CustomHTMLElement extends HTMLElement {
      connectedCallback() {
        const attrs = this.getAttribute('stock'); 
        const dropdown = this.getAttribute('isDropdown'); 

        ReactDOM.render(component({ stock: attrs,isDropdown:dropdown }), this);  // Render the React component in the custom element
      }
    }
    customElements.define(name, CustomHTMLElement);  // Define the custom element
  }
};

// Register custom elements
defineCustomElement('search-quotes',()=><QuotesSearch/>)
defineCustomElement('search-deep-stock', () => <Header />);

defineCustomElement('main-header-deep-stock', ({isDropdown}) => (
  <StockDataProvider>
    <MarketData isDropdown={isDropdown} /> 
  </StockDataProvider>
));
defineCustomElement('recently-viewed-deep-stock', () => <RecentlyViewTicker />);
defineCustomElement('releated-ticker-deep-stock', ({stock}) => <RelatedTicker stock={stock} />);


// Register the 'deep-stock-chart-cs' custom element
defineCustomElement('deep-stock-chart-cs', ({ stock }) => (
  <FinancialsProvider>
    <StockDataProvider>
      <Main stock={stock}/>
    </StockDataProvider>
  </FinancialsProvider>
));

// Function to update the stock attribute of the custom element


// Event listener for ticker selection


// Register the event listener on window load
const App: React.FC = () => {
  // useEffect(() => {
  //   const tickerListener = (event: Event) => {
  //     handleTickerSelected(event as CustomEvent);
  //   };

  //   window.addEventListener('tickerSelected', tickerListener as EventListener);

  //   return () => {
  //     window.removeEventListener('tickerSelected', tickerListener as EventListener);
  //   };
  // }, []);

  return (
    <div className="App">
      {/* <PolygonWebSocket/> */}
      {/* Uncomment the following if you want to include the StockDataProvider and Summary */}
      {/* <StockDataProvider>
        <Summary />
      </StockDataProvider> */}
      {/* <StockHover ticker='SOFI'/> */}
    </div>
  );
}

export default App;
