import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { StockChartProps } from "../types/chatinterface";
import moment from 'moment-timezone';
import useMarketHours from '../hooks/useMarketHours';
import { timezone } from '../constants/timezone';
import { timeRangeMap } from '../component/timeRange';

const MarketChart: React.FC<StockChartProps> = ({ dates, newElement, name, onTimeRangeChange, timeRange }) => {
  const { effectiveMarketCloseTime, effectiveMarketOpenTime } = useMarketHours();
  const chartRef = useRef(null);
  const [series, setSeries] = useState([{ name: name, data: dates }]);
  const [timeFormat, setTimeFormat] = useState<string | undefined>(undefined);

  const chartOptions: ApexOptions = {
    chart: {
      id: 'realtime-chart',
      zoom: { enabled: true },
      stacked: false,
      animations: {
        enabled: true,
        easing: 'easein',
        speed: 1000,
        dynamicAnimation: {
          enabled: false,
          speed: 1000,
        },
      },
      toolbar: { show: false },
    },
    dataLabels: { enabled: false },
    xaxis: {
      type: 'datetime',
      min: effectiveMarketOpenTime as number,
      max: effectiveMarketCloseTime as number,
      tickAmount: 12,
      tickPlacement: 'on',
      labels: {
        formatter: (value: any) => moment(value).tz(timezone).format(timeFormat || 'hh:mm A'),
        datetimeUTC: false,
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      x: {
        formatter: (value: any) => moment(value).tz(timezone).format('DD MMM yyyy HH:mm'),
      },
    },
    colors: ['#5D10FA'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.9,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  };

  // Update time format based on the selected timespan
  useEffect(() => {
    const formatMap: Record<string, string | undefined> = {
      minute: 'hh:mm A',
      week: undefined,
      month: 'MMM yyyy',
      day: 'dd:hh:mm A',
    };
    setTimeFormat(formatMap[timeRange.timespan]);
  }, [timeRange]);

  // Update series data when newElement changes
  useEffect(() => {
    if (newElement) {
      // @ts-ignore
      setSeries(prevSeries => (
        [
          {
            ...prevSeries,
            data: [...prevSeries[0].data, newElement]
          }
        ]
      ));
    }
  }, [newElement]);
  useEffect(() => {
    setSeries([{ name, data: dates }]);
  }, [dates, name]);
  

  // Time range options
  // const timeRangeMap = useMemo(() => [
  //   { label: '1', value: '1day', type: 'day', range: '1', timespan: 'minute' },
  //   { label: '1', value: '1year', type: 'year', range: '1', timespan: 'month' },
  //   { label: '5', value: '5day', type: 'day', range: '1', timespan: 'day' },
  //   { label: '1', value: '1month', type: 'month', range: '1', timespan: 'week' },
  //   { label: '3', value: '3month', type: 'month', range: '1', timespan: 'week' },
  //   { label: '6', value: '6months', type: 'month', range: '1', timespan: 'month' },
  //   { label: '3', value: '3years', type: 'year', range: '1', timespan: 'month' },
  // ], []);

  // Handle time range change
  // const handleTimeRangeChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedValue = event.target.value;
  //   const selectedOption = JSON.parse(selectedValue);
  //   onTimeRangeChange(selectedOption);
  // }, [onTimeRangeChange]);

  return (
    <div>
      {/* <select
    id="time-range-selector"
    onChange={handleTimeRangeChange}
    value={JSON.stringify(timeRangeMap.find(item => item.value === timeRange))}
    aria-label="Select time range"
>
    {timeRangeMap
        .filter(option => option.value !== "6months") // Exclude "6 months"
        .map((option) => (
            <option key={option.value} value={JSON.stringify(option)}>
                {option.value}
            </option>
        ))}
</select> */}

      <Chart options={chartOptions} series={series} type="area" ref={chartRef} height={255} />
    </div>
  );
};

export default MarketChart;
