import React from 'react';
import { SERVER_URL } from '../../../constants/environments';

interface MarketDataProps {
    symbol: string;
    direction: 'upside' | 'downside';
    change: string | number; // Change can be a string or number based on your data structure
}

const MarketDataComponent: React.FC<MarketDataProps> = ({ symbol, change, direction }) => {
    const handleClick = (tickerName: string) => {
        window.location.href = `${SERVER_URL}${tickerName}`;
      };    return (
        <div style={{ cursor: 'pointer' }} onClick={() => handleClick(symbol)}  className="tag">
            <div  className="text-block-2">{symbol}</div>
            <div className="data">
                <img
                    src={
                        direction === 'upside'
                            ? "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da92317caf51c5508aa041_Polygon%202%20(1).svg"
                            : "https://cdn.prod.website-files.com/66a243afe5b0bf88166b01da/66da97be2f98a346093a82b1_Polygon%201.svg"
                    }
                    loading="lazy"
                    alt=""
                    className={direction === 'upside' ? 'upside' : 'downside'}
                />
                <div className={`text-block-2 ${direction === 'upside' ? 'green' : 'red'}`}>
                    {change}
                </div>
            </div>
        </div>
    );
};

export default MarketDataComponent;
