import axios from 'axios';

 interface RequestCS {
  status:number
  message:string
  result:any
}
export const fetchData = async (url:string): Promise<RequestCS> => {
  try {
    const response = await axios.get<RequestCS,any>(url);
    return response.data; 
  } catch (error) {
    console.error("Error fetching data:", error);
    return {
      status:500,
      message: "An error occurred",
      result: null,
    };
  }
};

export const postData=async (url:string, data:any) => {
  try {
    const response = await axios.post(url, data,);
    return response.data;
  } catch (error) {
    console.error('POST Error:', error);
    throw error;
  }
}