import React, { useContext, useEffect, useState } from 'react';
import { FinancialsContext } from '../apis/FinancialsContext';
import { MainDataProps } from '../App';

const Financials: React.FC<MainDataProps> = ({ stock }) => {
    const { financialData, loading, error, fetchFinancialData } = useContext(FinancialsContext);
    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(() => {
        fetchFinancialData(stock); 
    }, [stock]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    if (!financialData || financialData.length === 0) {
        return <div>No financial data available.</div>;
    }

    return (
        <div>
            <div className="tabs finance-tabs">
                {financialData.map((item, index) => (
                    <button 
                        key={index} 
                        onClick={() => setActiveTab(index)} 
                        className={activeTab === index ? 'active' : ''}
                    >
                        {item.endDate}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                <div>
                    <h3 className='mb-3'>Financials for {financialData[activeTab].endDate}</h3>
                    <div className="row finance-row">
                        <div className="col-md-3">
                            <div className='finance-text'>
                                <h6>Revenue:</h6>
                                <p>${financialData[activeTab].Revenue.toLocaleString()}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='finance-text'>
                                <h6>Net Income: </h6>
                                <p>${financialData[activeTab].NetIncome.toLocaleString()}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='finance-text'>
                                <h6>Diluted EPS:</h6>
                                <p> {financialData[activeTab].DilutedEPS}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='finance-text'>
                                <h6>Net Profit Margin:</h6>
                                <p>{financialData[activeTab].NetProfitMargin}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='finance-text'>
                                <h6>Operating Income:</h6>
                                <p>${financialData[activeTab].OperatingIncome.toLocaleString()}</p>
                            </div>
                        </div>  
                        <div className="col-md-3">
                            <div className='finance-text'>
                                <h6>Net Change In Cash:</h6>
                                <p>${financialData[activeTab].NetChangeInCash.toLocaleString()}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='finance-text'>
                                <h6>Cash On Hand:</h6>
                                <p>{financialData[activeTab].CashOnHand}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='finance-text'>
                                <h6>Cost Of Revenue:</h6>
                                <p>${financialData[activeTab].CostOfRevenue.toLocaleString()}</p>
                            </div>
                        </div>     
                    </div>                      
                </div>
            </div>
        </div>
    );
};

export default Financials;