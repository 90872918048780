import { io, Socket } from 'socket.io-client';
import { BASE_URI } from '../constants/environments';

export enum SocketType {
  STOCKS = 'stocks',
  FOREX = 'forex' ,
  CRYPTO = 'crypto',
}

interface SocketObjType {
  [key: string]: Socket | null;
}

class WebsocketService {
  private sockets: SocketObjType = {
    stocks: null,
    forex: null,
    crypto: null,
  };

  private subscribedTickers: Record<SocketType, Set<string>> = {
    stocks: new Set(),
    forex: new Set(),
    crypto: new Set(),
  };

  private subscribedTickersListner: any = {
    stocks: new Set(),
    forex: new Set(),
    crypto: new Set(),
  };

  private readonly urls: Record<SocketType, string> = {
    stocks: `${BASE_URI}/stocks`,
    forex: `${BASE_URI}/forex`,
    crypto: `${BASE_URI}/crypto`,
  };
 

  constructor() {
    this.connect(SocketType.STOCKS);
    this.connect(SocketType.FOREX);
    this.connect(SocketType.CRYPTO);
  }

  private connect(type: SocketType) {
    this.sockets[type] = io(this.urls[type], {
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    this.sockets[type]?.on('connect', () => {
      this.resubscribeTickers(type);
    });

    this.sockets[type]?.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        this.sockets[type]?.connect();
      }
    });

    this.sockets[type]?.on('connect_error', (error) => {

    });
  }

  private resubscribeTickers(type: SocketType) {
    const socket = this.sockets[type];
    const subscribedTickers = this.subscribedTickers[type];
    const subscribedTickersListner = this.subscribedTickersListner[type]


    if (socket && subscribedTickers.size > 0) {
      subscribedTickers.forEach((ticker) => {
        socket.emit('subscribe', ticker);
        socket.on(ticker, subscribedTickersListner[ticker])
      });
    }
  }

  public subscribe(ticker: string, type: SocketType, eventType: string = '') {
    const socket = this.sockets[type];
    const subscribedTickers = this.subscribedTickers[type];

    if (socket) {
      subscribedTickers.add(ticker);
      socket.emit('subscribe', ticker, eventType);

    } else {
      console.warn(`Socket for ${type} not connected`);
    }
  }
  public unSubscribe(ticker: string, type: SocketType) {
    const socket = this.sockets[type];
    if (socket) {
      socket.emit('unsubscribe', ticker);

    } else {
      console.warn(`Socket for ${type} not connected`);
    }
  }

  public onMessage(type: SocketType, stock: string, callback: (data: any) => void, ag = false) {
    const socket = this.sockets[type];

    if (socket) {
      this.subscribedTickersListner[type][stock] = callback;

      socket.on(`Event${(ag == true) ? '.AM' : ''}.${stock}`, callback)



    } else {
      this.subscribedTickersListner[type][stock] = callback;
      console.warn(`Socket for ${type} not connected`);
    }
  }

  public disconnect(type: SocketType) {
    const socket = this.sockets[type];

    if (socket) {
      socket.disconnect();
    }
  }
}

export default new WebsocketService();



