export const timeRangeMap = [
    { label: '1D', value: '1day', type: 'day', range: '1', timespan: 'minute',ogTimeSpan:'minute',frequency:'1min' },
    { label: '5D', value: '5day', type: 'day', range: '1', timespan: 'day',ogTimeSpan:'hour', frequency:'hourly' },
    { label: '1M', value: '1month', type: 'month', range: '1', timespan: 'week',ogTimeSpan:'day',frequency:'daily'  },
    { label: '3M', value: '3month', type: 'month', range: '1', timespan: 'week',ogTimeSpan:'day',frequency:'daily'  },
    { label: '6M', value: '6months', type: 'month', range: '1', timespan: 'month',ogTimeSpan:'week',frequency:'weekly'  },
    { label: '1Y', value: '1year', type: 'year', range: '1', timespan: 'month',ogTimeSpan:'month',frequency:'monthly'  },
    { label: '3Y', value: '3years', type: 'year', range: '1', timespan: 'month',ogTimeSpan:'month' ,frequency:'monthly' },
  ];

export const timeRangeMap90D = [
  { label: '90D', value: '90day', type: 'day', range: '1', timespan: 'day',ogTimeSpan:'week', frequency:'hourly' },
]  

  interface TimeOption {
    label: string;
    value: string;
    disabled?: boolean; 
  }

  export const timeRange=[{

  }]
  

  export const timeOptions: TimeOption[] = [
    { label: '1min', value: '1min' },
    { label: '5min', value: '5min' },
    { label: '10min', value: '10min' },
    { label: '15min', value: '15min' },
    { label: 'hourly', value: 'hourly' },
    { label: 'daily', value: 'daily' },
    { label: 'weekly', value: 'weekly' },
    { label: 'monthly', value: 'monthly' },
    { label: 'quarterly', value: 'quarterly' },
    { label: 'yearly', value: 'yearly' },
  ];