import React, { useEffect, useState } from 'react'
import { endpoints } from '../constants/environments';
import { fetchData } from '../apis/Apis';
import { MainDataProps } from '../App';
const CompanyInfo:React.FC<MainDataProps> = ({ stock }) => {
    const [comapnyInfo,setComapanyInfo]= useState<any>();

    
const getCompanyData = async (ticker:string) => {
    try {
      const response = await fetchData(`${endpoints.GET_COMPANY_DATA}/${ticker}`);
      setComapanyInfo(response.result.companyData);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    getCompanyData(stock);   
  }, [stock]);

  return (
    <div>
      <h1 className='mb-3'>{comapnyInfo?.name}</h1>
      <div className='company-info'>
        <h6>Description:</h6>
        <p>{comapnyInfo?.description}</p>
      </div>
      <div className='company-info'>
        <h6>Address:</h6>
        <p>{comapnyInfo?.address?.address1}{comapnyInfo?.address?.city}{comapnyInfo?.address?.postal_code}{comapnyInfo?.address?.state}</p>
      </div>
      <div className='company-info'>
        <h6>Phone:</h6>
        <p>{comapnyInfo?.phone_number}</p>
      </div>
      <div className='company-info'>
        <h6>Total Employees:</h6>
        <p>{comapnyInfo?.total_employees}</p>
      </div>
    </div>
  )
}

export default CompanyInfo