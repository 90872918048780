import React, { useEffect, useState } from 'react';
import { fetchData } from '../apis/Apis';
import { endpoints } from '../constants/environments';
import { MainDataProps } from '../App';

interface TradeData {
    open: number;
    dayRange: string;
    fiftyTwoWeekRange: string;
    marketCap: number;
    sharesOutstanding: number;
    publicFloat: number;
    revenuePerEmployee: number;
    peRatio: number;
    eps: number;
    yield: string | 'N/A';
    dividend: string | 'N/A';
    exDividendDate: string | 'N/A';
    shortInterest: string | 'N/A';
    percentOfFloatShorted: string | 'N/A';
    averageVolume: number;
    beta:string


  }


const Overview: React.FC<MainDataProps> = ({ stock }) => {
    const [responseData, setResponseData] = useState<TradeData | null>(null); 

    const getOverviewData = async (ticker: string) => {
        try {
          const response = await fetchData(`${endpoints.OVERVIEW_DATA}/${ticker}`);
          const trade: TradeData = response.result;
          setResponseData(trade);
        } catch (error) {
          console.error('Error fetching trade data', error);
        }
      };
    
      useEffect(() => {
        if(stock){
          getOverviewData(stock); 

        }
      }, [stock]);
  return (
    <div className="overview mt-70">
      <h1 className="f-30">Overview</h1>
      <div className="overview_data">
        <div id="w-node-cce39f92-2aef-74a8-91af-9fe36f7cbba1-022d2056" className="info-box">
          <div className="light">OPEN</div>
          <h4 className="f-20">{responseData?.open}</h4>
        </div>
        <div id="w-node-d45d09ee-f3bd-6002-d6d1-54c145cebe5d-022d2056" className="info-box">
          <div className="light">DAY RANGE</div>
          <h4 className="f-20">{responseData?.dayRange}</h4>
        </div>
        <div id="w-node-d85b9a20-48ee-662b-0a10-262b16f578d0-022d2056" className="info-box">
          <div className="light">52 WEEK RANGE</div>
          <h4 className="f-20">{responseData?.fiftyTwoWeekRange}</h4>
        </div>
        <div id="w-node-beb29aba-4aeb-6c56-1b47-5bbe8d242e37-022d2056" className="info-box">
          <div className="light">MARKET CAP</div>
          <h4 className="f-20">{responseData?.marketCap}</h4>
        </div>
        <div id="w-node-a681fea4-a53c-1bb3-7f22-3aad1450986a-022d2056" className="info-box">
          <div className="light">SHARES OUTSTANDING</div>
          <h4 className="f-20">{responseData?.sharesOutstanding}</h4>
        </div>
        <div id="w-node-b00e82dd-2dd8-fd28-d570-251283a744ae-022d2056" className="info-box">
          <div className="light">PUBLIC FLOAT</div>
          <h4 className="f-20">{responseData?.publicFloat}</h4>
        </div>
        <div id="w-node-_15ff9dca-fb13-fdb5-a11f-106d0b178360-022d2056" className="info-box">
          <div className="light">BETA</div>
          <h4 className="f-20">{responseData?.beta}</h4>
        </div>
        <div id="w-node-_4dc22532-76a2-2ac2-a5fe-7d9fe2f329df-022d2056" className="info-box">
          <div className="light">REV. PER EMPLOYEE</div>
          <h4 className="f-20">{responseData?.revenuePerEmployee}</h4>
        </div>
        <div id="w-node-a52023fc-ee6c-e1c5-e35f-0fe9e87c4653-022d2056" className="info-box">
          <div className="light">P/E RATIO</div>
          <h4 className="f-20">{responseData?.peRatio}</h4>
        </div>
        <div id="w-node-_9f73b0c2-ad4d-0c83-828d-4aaa46287a5d-022d2056" className="info-box">
          <div className="light">EPS</div>
          <h4 className="f-20">{responseData?.eps}</h4>
        </div>
        <div id="w-node-ef024d28-4f7a-bb97-d20f-288852b1089b-022d2056" className="info-box">
          <div className="light">YIELD</div>
          <h4 className="f-20">{responseData?.yield}</h4>
        </div>
        <div id="w-node-_26f92def-42ba-e4d7-c2a4-fefc48852d8b-022d2056" className="info-box">
          <div className="light">DIVIDEND</div>
          <h4 className="f-20">{responseData?.dividend}</h4>
        </div>
        <div id="w-node-_07350818-0ae5-6ae2-3f43-731e2fb19a75-022d2056" className="info-box">
          <div className="light">EX-DIVIDEND DATE</div>
          <h4 className="f-20">{responseData?.exDividendDate}</h4>
        </div>
        <div id="w-node-a08d46f8-555a-bedf-5056-f28119d10bb7-022d2056" className="info-box">
          <div className="light">SHORT INTEREST</div>
          {/* <h4 className="f-20">{responseData?.shortInterest} <span className="text-span"></span></h4> */}
          <h4 className="f-20">78.7M <span className="text-span"></span></h4>

        </div>
        <div id="w-node-_75fd2ddb-862d-021a-8bd8-3af3a20b785f-022d2056" className="info-box">
          <div className="light">% OF FLOAT SHORTED</div>
          {/* <h4 className="f-20">{responseData?.percentOfFloatShorted}</h4> */}
          <h4 className="f-20">2.82%</h4>

        </div>
        <div id="w-node-_9ff6f52c-39a5-3ae6-556c-504ff956ab40-022d2056" className="info-box">
          <div className="light">AVERAGE VOLUME</div>
          <h4 className="f-20">{responseData?.averageVolume}</h4>
          {/* <h4 className="f-20">96.24M</h4> */}

        </div>
      </div>
    </div>
  );
};

export default Overview;