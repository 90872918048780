// FinancialsContext.tsx
import React, { createContext, useState, ReactNode } from 'react';
import { fetchData } from '../apis/Apis';
import { endpoints } from '../constants/environments';
import { ApiResponse, FinancialData } from '../types/chatinterface';

interface FinancialsContextProps {
    financialData: FinancialData[] | undefined;
    loading: boolean;
    error: string | null;
    fetchFinancialData: (ticker: string) => void;
}

export const FinancialsContext = createContext<FinancialsContextProps>({
    financialData: undefined,
    loading: false,
    error: null,
    fetchFinancialData: () => {},
});

export const FinancialsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [financialData, setFinancialData] = useState<FinancialData[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchFinancialData = async (ticker: string) => {
        setLoading(true);
        setError(null);
        try {
            const data: ApiResponse = await fetchData(`${endpoints.FINANCE_DATA}/${ticker}`);
            if (data.status === 200) {
                setFinancialData(data.result.financials);
            } else {
                setError(data.message);
            }
        } catch (err) {
            setError('Failed to fetch financial data');
        } finally {
            setLoading(false);
        }
    };

    return (
        <FinancialsContext.Provider value={{ financialData, loading, error, fetchFinancialData }}>
            {children}
        </FinancialsContext.Provider>
    );
};
