// StockDataContext.tsx
import React, { createContext, useState, ReactNode } from 'react';
import { StockResult } from '../types/chatinterface';
import { postData } from '../apis/Apis';
import { endpoints } from '../constants/environments';

interface StockDataContextProps {
  data: StockResult[] | undefined;
  fetchData: (ticker: string, timeRange: { label: string; value: string; type: string,range:string,timespan:string,ogTimeSpan:string }) => void;
}

export const StockDataContext = createContext<StockDataContextProps>({
  data: undefined,
  fetchData: () => {},
});

export const StockDataProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<StockResult[] | undefined>(undefined);

  const fetchData = async (ticker: string, timeRange: { label: string; value: string; type: string,range:string,timespan:string }) => {
    const payload = {
      ticker: ticker,
      timeRange: timeRange,
    };
    try {
      let url = `${endpoints.GET_STOCK_DATA}`;
      const response = await postData(url, payload);
      setData(response.result as StockResult[]);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  return (
    <StockDataContext.Provider value={{ data, fetchData }}>
      {children}
    </StockDataContext.Provider>
  );
};
