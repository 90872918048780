import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MarketChart from '../../../apex-chats/MarketChart';
import Ticker from './Ticker';
import MarketHeader from './MarketHeader';
import { TabEnum } from '../../../constants/tabs';
import { SocketType } from '../../../sockets/polygon';
import useMarketIndex from '../../../hooks/useMarketIndex';
import useMarketHours from '../../../hooks/useMarketHours';
import { StockDataContext } from '../../../apis/StockDataContext';
import { ChartData } from '../../Summary';
import useMarketHeaderData from '../../../hooks/useMarketHeaderData';
import websocketService from '../../../sockets/polygon';
import { formats } from '../../formats';
import moment from 'moment';


interface TabsComponentProps {
    isDropdownVisible: boolean;
}

const TabsComponent: React.FC<TabsComponentProps> = React.memo(({
    isDropdownVisible,
}) => {
    const marketHeader = useMarketHeaderData();
    const memoizedMarketHeaderData = useMemo(() => marketHeader, [marketHeader])
    const { activeTab, marketIndexData, updateActiveTab } = useMarketIndex();
    // Use memo to avoid re-rendering caused by unchanged `marketIndexData`
    const memoizedMarketIndexData = useMemo(() => marketIndexData, [marketIndexData]);

    // Memoize the update function to avoid creating a new function reference each time
    const memoizedUpdateActiveTab = useCallback((newTab: TabEnum) => {
        updateActiveTab(newTab);
    }, [updateActiveTab]);

    const { isCurrentTimeInBetweenNineThirtyAndFour } = useMarketHours();


    const { data, fetchData } = useContext(StockDataContext);

    const memoizedMarketChartData = useMemo(() => data, [data]);

    const [formattedData, setFormattedData] = useState<ChartData[]>([]);
    const [newChartData, setNewChartData] = useState<ChartData | null>(null);
    const [timeRange, setTimeRange] = useState({ label: '1', value: '1day', type: 'day', range: '1', timespan: 'minute', ogTimeSpan: 'minute' },);
    const [activeTicker, setActiveTicker] = useState<string>('SPY');
    const [unsubscribeTicker, setUnsubscribeTicker] = useState<string>('');
    const [opacity, setOpacity] = useState(1);


    // Chart Updates
    useEffect(() => {
        if (fetchData) {
            let ticker = activeTicker
            if (activeTab !== TabEnum.Stocks) {
                ticker = ticker.replace("-", "")
            }
            fetchData(ticker, timeRange);
        }
    }, [timeRange, activeTicker]);



    useEffect(() => {
        if (memoizedMarketChartData) {
            const mappedData:any = memoizedMarketChartData.map((item) => {
                const tempDate = moment(item.t);
                let formattedDate: string;
    
                switch (timeRange.ogTimeSpan) {
                    case 'minute':
                        formattedDate = item.t as any;
                        break;
                    case 'hour':
                        formattedDate = tempDate.format(formats.hour);
                        break;
                    case 'day':
                        formattedDate = tempDate.format(formats.day);
                        break;
                    case 'week':
                        formattedDate = tempDate.format(formats.week);
                        break;
                    case 'month':
                        formattedDate = tempDate.format(formats.month);
                        break;
                    default:
                        formattedDate = tempDate.format(formats.default);
                }

                return { x: formattedDate, y: item.c };
            });

            setFormattedData(mappedData); 
            setNewChartData(null);
            setOpacity(1);
        }
    }, [memoizedMarketChartData, timeRange, formats]);
    



    useEffect(() => {
        const handleData = (data: any) => {
            if (data.sym == activeTicker && isCurrentTimeInBetweenNineThirtyAndFour) {
                const newPoint: ChartData = { x: data.t, y: data.fmv };
                setNewChartData(newPoint);
            }

        };

        if (activeTicker && isDropdownVisible && isCurrentTimeInBetweenNineThirtyAndFour) {
            websocketService.subscribe(activeTicker, SocketType.STOCKS);
            let type: SocketType = SocketType.STOCKS;
            if (activeTab == TabEnum.Crypto) {
                type = SocketType.CRYPTO;
            } else if (activeTab == TabEnum.Forex) {
                type = SocketType.FOREX;
            }
            websocketService.onMessage(type, activeTicker, handleData);
        }

        return () => {

        };
    }, [activeTicker, isDropdownVisible, isCurrentTimeInBetweenNineThirtyAndFour]);


    useEffect(() => {
        let type: SocketType = SocketType.STOCKS;
        if (activeTab == TabEnum.Crypto) {
            type = SocketType.CRYPTO;
        } else if (activeTab == TabEnum.Forex) {
            type = SocketType.FOREX
        }
        websocketService.unSubscribe(unsubscribeTicker, type)
    }, [unsubscribeTicker])

    const handleTabClick = useCallback((type: TabEnum) => {
        memoizedUpdateActiveTab(type);
    }, [memoizedUpdateActiveTab]);


    const handleClick = useCallback((ticker: string) => {
        // Determine the type based on the active tab
        let type: SocketType = SocketType.STOCKS;
        if (activeTab === TabEnum.Crypto) {
            type = SocketType.CRYPTO;
        } else if (activeTab === TabEnum.Forex) {
            type = SocketType.FOREX;
        }

        // Unsubscribe from the current active ticker
        websocketService.unSubscribe(activeTicker, type);

        // Set the new active ticker
        setActiveTicker(ticker);
    }, [activeTab, activeTicker]);


    const handleTimeRangeChange = useCallback((timeRange: any) => {
        setTimeRange(timeRange);
    }, [])

    // Memoize the tickers for the active tab
    const tickersForActiveTab = useMemo(() => {
        return memoizedMarketIndexData[activeTab];
    }, [memoizedMarketIndexData, activeTab]);

    if (!isDropdownVisible) return null;
    return (
        <div className="full-area-drop-down display-market-data">
            <div className="left-table">
                <div className="table-head" >
                    <div className="th" onClick={() => handleTabClick(TabEnum.Stocks)}>
                        <h1 className={`thead ${activeTab === TabEnum.Stocks ? 'active' : ''}`}>US</h1>
                    </div>
                    <div className="th" onClick={() => handleTabClick(TabEnum.Forex)}>
                        <h1 className={`thead ${activeTab === TabEnum.Forex ? 'active' : ''}`}>FX</h1>
                    </div>
                    <div className="th" onClick={() => handleTabClick(TabEnum.Crypto)}>
                        <h1 className={`thead ${activeTab === TabEnum.Crypto ? 'active' : ''}`}>Crypto</h1>
                    </div>
                </div>
                <div className="table-data">
                    
                    {tickersForActiveTab.map((item, index) => (
                        <Ticker
                            key={item.ticker}
                            ticker={item.ticker}
                            session={item.session}
                            last_quote={item.last_quote} // Assuming 'last_quote' is the same as 'fmv' in this context
                            isActive={activeTicker === item.ticker}
                            activetab={activeTab}
                            onClick={handleClick}
                        />
                    ))}
                </div>
            </div>

            <div className="map-here center-map" style={{ opacity: opacity }}>
    <MarketChart
        newElement={newChartData}
        dates={formattedData}
        name={activeTicker}
        onTimeRangeChange={handleTimeRangeChange}
        timeRange={timeRange}
    />
            </div>
            <MarketHeader marketHeader={memoizedMarketHeaderData} />
        </div>
    );
});

export default TabsComponent;
