import './App.css';
import React, { useEffect } from 'react';
import { StockDataProvider } from './apis/StockDataContext';
import ReactDOM from 'react-dom';
import Main from './pages/Main';
import { FinancialsProvider } from './apis/FinancialsContext';
import MarketData from './component/MarketData';
import Header from './component/Header';
import RecentlyViewTicker from './component/RecentlyViewTicker';
import QuotesSearch from './component/QuotesSearch';
import ReleatedTickerr from './component/ReleatedTickerr';
import RelatedTicker from './component/ReleatedTickerr';
import StockHover from './sdk/StockHover';



export interface MainDataProps {
  stock: string; // Define the type of the stock prop
}


// Define a function to register the custom element
// const defineCustomElement = (name: string, component: (attrs?: any,dropdown?:any) => JSX.Element) => {
//   if (!customElements.get(name)) {  // Check if the custom element is already defined
//     class CustomHTMLElement extends HTMLElement {
//       connectedCallback() {
//         const attrs = this.getAttribute('stock'); 
//         const dropdown = this.getAttribute('isDropdown'); 
//         const user_id = this.getAttribute('user_id'); 

//         ReactDOM.render(component({ stock: attrs,isDropdown:dropdown,user_id:user_id }), this);  // Render the React component in the custom element
//       }
//     }
//     customElements.define(name, CustomHTMLElement);  // Define the custom element
//   }
// };

const defineCustomElement = (name: string, component: (attrs?: any, dropdown?: any) => JSX.Element) => {
  if (!customElements.get(name)) { // Check if the custom element is already defined
    class CustomHTMLElement extends HTMLElement {
      private reactRoot: any;

      static get observedAttributes() {
        // Specify attributes to observe
        return ['stock', 'isDropdown', 'user_id','tickers'];
      }

      constructor() {
        super();
        this.reactRoot = null;
      }

      connectedCallback() {
        this.renderReactComponent(); // Render the React component when the custom element is added to the DOM
      }

      attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (oldValue !== newValue) {
          this.renderReactComponent(); // Re-render React component when observed attributes change
        }
      }

      disconnectedCallback() {
        if (this.reactRoot) {
          ReactDOM.unmountComponentAtNode(this); // Clean up React component when custom element is removed
          this.reactRoot = null;
        }
      }

      renderReactComponent() {
        const attrs = this.getAttribute('stock');
        const dropdown = this.getAttribute('isDropdown');
        const user_id = this.getAttribute('user_id');
        const tickers = this.getAttribute('tickers');
        const login_page_url = this.getAttribute('login_page_url');
        const action = this.getAttribute('action');

        // Render React component and save the root
        this.reactRoot = ReactDOM.render(
          component({ stock: attrs, isDropdown: dropdown, user_id,tickers,login_page_url,action }),
          this
        );
      }
    }

    // Define the custom element
    customElements.define(name, CustomHTMLElement);
  }
};
// Register custom elements
defineCustomElement('search-quotes',()=><QuotesSearch/>)
defineCustomElement('search-deep-stock', () => <Header />);

defineCustomElement('main-header-deep-stock', ({isDropdown}) => (
  <StockDataProvider>
    <MarketData isDropdown={isDropdown} /> 
  </StockDataProvider>
));
defineCustomElement('recently-viewed-deep-stock', () => <RecentlyViewTicker type='recently'/>);

defineCustomElement('watchlist-tickers', ({tickers,action}) => <RecentlyViewTicker tickers={tickers} action={action} type='watchlist' />);

defineCustomElement('releated-ticker-deep-stock', ({stock}) => <RelatedTicker stock={stock} />);


// Register the 'deep-stock-chart-cs' custom element
defineCustomElement('deep-stock-chart-cs', ({ stock,user_id,login_page_url }) => (
  <FinancialsProvider>
    <StockDataProvider>
      <Main stock={stock} user_id={user_id} login_page_url={login_page_url}/>
    </StockDataProvider>
  </FinancialsProvider>
));

// Function to update the stock attribute of the custom element


// Event listener for ticker selection


// Register the event listener on window load
const App: React.FC = () => {
  // useEffect(() => {
  //   const tickerListener = (event: Event) => {
  //     handleTickerSelected(event as CustomEvent);
  //   };

  //   window.addEventListener('tickerSelected', tickerListener as EventListener);

  //   return () => {
  //     window.removeEventListener('tickerSelected', tickerListener as EventListener);
  //   };
  // }, []);

  return (
    <div className="App">
      {/* <PolygonWebSocket/> */}
      {/* Uncomment the following if you want to include the StockDataProvider and Summary */}
      {/* <StockDataProvider>
        <Summary />
      </StockDataProvider> */}
      {/* <StockHover ticker='SOFI'/> */}
    </div>
  );
}

export default App;
